<template>
	<div class="enterprise">
		<div class="">
			<div  class="ltr" style="height: auto;">
				<a-anchor :getCurrentAnchor="getCurrentAnchor" >
				   <a-anchor-link v-for="(item,index) in mailInfo_list" :key='index' :href="'#'+item.type" :title="item.name" />
				 </a-anchor>
			</div>
		
			<div class="card_cont">
				<div v-for="(item,index) in mailInfo_list" :id='item.type' :key='index'>
					<div class="top_info" style='margin-top: 0px;'>
						<div class="title_name">{{item.name}}</div>
						<!-- <div class="title_btn">
							<FormOutlined @click='go_FieldSettings' :style="{color: '#12adf8'}" />
							<span class="cq_text_icon" @click='go_FieldSettings'>编辑</span>
						</div> -->
					</div>
					<div>
						<p class="cq_text_tips">
							{{item.describe}}
						</p>

						<div class="infoBox">
							<!-- 入职 -->
							<div v-if="item.type=='entry_rule'">
								<div v-for="(it,index) in item.value" :key='index'>
									<p class="item_p"><span class="title_name mar8">{{it.name}}</span><span
											class="cq_text_tips ">{{it.describe}}</span></p>
									<div v-if="it.type=='entry_age_limit'">
										<div v-for="(item_p,index) in it.value" :key='index'>
											<p class="item_p" v-if="item_p.type=='min_age'">
												<a-checkbox class="mar8"  @change='set_info(it)' v-model:checked="item_p.is_true"></a-checkbox>
												<span class="mar8">入职员工需满</span>
												<a-select class="mar8 w100" @change='set_info(it)' v-model:value="item_p.value">
													<a-select-option :value="y" v-for="(y,index) in txDay" :key='index'>{{y}}
													</a-select-option>
												</a-select>
												<span>周岁</span>
											</p>
											<p class="item_p" v-if="item_p.type=='max_age'">
												<a-checkbox class="mar8"  @change='set_info(it)' v-model:checked="item_p.is_true"></a-checkbox>
												<span class="mar8">入职员工年龄不超过</span>
												<a-select class="mar8 w100"  @change='set_info(it)' v-model:value="item_p.value">
													<a-select-option :value="y" v-for="(y,index) in txDay" :key='index'>{{y}}
													</a-select-option>
												</a-select>
												<span>周岁</span>
											</p>
										</div>
									</div>

									<div v-if="it.type=='entry_again_limit'">
										<div >
											<p class="item_p">
												<a-checkbox  @change='set_info(it)' class="mar8" v-model:checked="it.value.is_true"></a-checkbox>
												<span>允许重新入职</span>
											</p>

										</div>
									</div>

									<div v-if="it.type=='leave_time_limit'">
										<a-radio-group  @change='set_info(it)' v-model:value="it.value[0].type">
											<p class="item_p">
												<a-radio value="no_limit"> </a-radio>
												<span> 不限制，可以随时重新入职 </span>
											</p>
											<p class="item_p">
												<a-radio value="limit"> </a-radio>
												<span class="mar8">距离上次离职</span>
												<a-select class="mar8 w100" @change='set_info(it)' v-model:value="it.value[0].value">
													<a-select-option :value="y" v-for="(y,index) in againMonth" :key='index'>{{y}}
													</a-select-option>
												</a-select>
												<span>个月后可重新入职</span>
											</p>

										</a-radio-group>
									</div>
								</div>

							</div>

							<!-- 退休规则 -->
							<div v-if="item.type=='retire_rule'">
								<p class="item_p">
									<span class="mar8">男职工退休年龄为</span>
									<a-select class="mar8 w100"  @change='set_info(item)' v-model:value="item.value.man">
										<a-select-option :value="y" v-for="(y,index) in retire" :key='index'>{{y}}
										</a-select-option>
									</a-select> <span>周岁</span>
								</p>
								<p class="item_p">
									<span class="mar8">女职工退休年龄为</span>
									<a-select class="mar8 w100"  @change='set_info(item)'  v-model:value="item.value.woman">
										<a-select-option :value="y" v-for="(y,index) in retire" :key='index'>{{y}}
										</a-select-option>
									</a-select> <span>周岁</span>
								</p>
							</div>

							<!-- 工号规则 -->
							<div v-if="item.type=='user_code_rule'">
								<div v-for="(it,index) in item.value" :key='index' class="mar_30">
									<p class="item_p" v-if="it.name"><span class="title_name mar8">{{it.name}}</span><span
											class="cq_text_tips ">{{it.describe}}</span></p>
									<p class="item_p " v-if="it.type=='none_code_user'">
										当前有 <span class="cq_text_warring">{{it.value}}</span> 名在职员工工号信息未维护 <span
											class="cq_color" @click="go_none_list">去补充</span>
									</p>
									<p class="item_p " v-if="it.type=='drp_coding_situation'">
										<span class="mar8">企业工号约束</span>
										<a-radio-group  @change='set_info(it)' v-model:value="it.value.type">
											<a-radio value="not_allow"> 完全不允许重复 </a-radio>
											<a-radio value="part_allow"> 在职员工不允许重复 </a-radio>
											<a-radio value="allow"> 允许重复 </a-radio>
										</a-radio-group>
									</p>
									<p class="item_p " v-if="it.type=='code_rule'">
										<a-switch  @change='set_info(it)' checked-children="开" un-checked-children="关"
											v-model:checked="it.value.is_true" />
									</p>
									
									<div class="item_p " v-if="it.type=='code_create_rule'  ">
										<div style="text-align: right;">
											<a-button type="primary" @click='addVisible=true'>添加工号规则</a-button>
										</div>
										
										<div class="FieldSettingsBox">
										    <div class="FieldSettingsBoxTitle FieldSettingsBoxHeader">
										        <span style="flex: 1;">
										           规则名称
										        </span> <span style="flex: 1;">最新未用工号</span><span style="flex: 1;">工号前缀</span><span style="flex: 1;">数字位数</span><span style="flex: 1;">描述</span> <span style="color: #666;">操作</span>
										    </div>
										    <div class="FieldSettingsBoxTitle"  v-for="(op,index) in it.value" :key="index">
										        <span style="flex: 1;">
										            {{op.name}}
										        </span>
												<span style="flex: 1;">
												    {{op.code_begin}}
												</span>
												<span style="flex: 1;">
												    {{op.number_prefix}}
												</span>
												<span style="flex: 1;">
												    {{op.figure}}
												</span>
										        <span style="flex: 1;">
										            {{op.describe}}
										        </span>
										        <span  class="cq_color"  >
													<span  @click='go_info(op)'  class="cq_color">编辑 </span> 
												
												  <a-popconfirm
												     title="是否确认?"
												     ok-text="是"
												     cancel-text="否"
												     @confirm="del_info(op)"
												     @cancel="cancel"
												   >
												   <span style="margin-left: 6px;"  class="cq_error">删除</span>
												   </a-popconfirm>
												 </span>
										    </div>
										</div>
									</div>
								</div>
							</div>
							
							<!-- 添加人员规则 -->
							<div v-if="item.type=='add_user_rule'">
								<div class="FieldSettingsBox">
								    <div class="FieldSettingsBoxTitle FieldSettingsBoxHeader">
								        <span>
								            添加场景
								        </span> <span>描述说明</span> <span style="color: #666;">操作</span>
								    </div>
								    <div class="FieldSettingsBoxTitle"  v-for="(it,index) in item.value" :key="it.id">
								        <span>
								            {{it.name}}
								        </span>
								        <span>
								            {{it.describe}}
								        </span>
								        <span  class="cq_color" @click='go_list(index+1)' >去设置</span>
								    </div>
								</div>
								
							</div>
							
							<!-- 人事通知规则 -->
							<div v-if="item.type=='hr_notice_rule'">
								<div class="FieldSettingsBox">
								    <div class="FieldSettingsBoxTitle FieldSettingsBoxHeader">
								        <span>
								          人事事件
								        </span> <span>是否开启提醒</span> <span style="color: #666;">多久提醒</span>
								    </div>
								    <div class="FieldSettingsBoxTitle"  v-for="it in item.value" :key="it.id">
								        <span>
								         {{it.name}}
								        </span>
								        <span>
								            <a-switch checked-children="开" un-checked-children="关" @change='set_info(item)'
								            	v-model:checked="it.is_true" />
								        </span>
								        <span  class="cq_color" >
											  <a-radio-group  @change='set_info(item)' v-model:value="it.value">
											    <a-radio-button :value="7" class="mar8">7天</a-radio-button>
											    <a-radio-button :value="15" class="mar8">15天</a-radio-button>
											    <a-radio-button :value="30" class="mar8">30天</a-radio-button>
											  </a-radio-group>
											   <a-select  @change='set_info(item)'  style="width: 100px;"  v-model:value="it.value">
											   	<a-select-option :value="y" v-for="(y,index) in 30" :key='index'>{{y}} 天
											   	</a-select-option>
											   </a-select> 
										</span>
								    </div>
								</div>
							</div>
							
							

							<!-- 转正规则 -->
							<div v-if="item.type=='probationary_rule'">
								<a-radio-group  @change='set_info(item)' v-model:value="item.value.type">
									<p class="item_p">
										<a-radio value="intraday"> 试用期满当天转正 </a-radio>
									</p>
									<p class="item_p">
										<a-radio value="before"> 试用期满前一天转正 </a-radio>
									</p>
									<p class="item_p">
										<a-radio value="after"> 试用期满次日转正 </a-radio>
									</p>
								</a-radio-group>
							</div>
							<!-- 离职 -->
							<!-- <div v-if="item.type=='leave_rule'">
								<a-radio-group  @change='set_info(item)' v-model:value="item.value.type">
									<p class="item_p">
										<a-radio value="need_work"> 离职日当天无需上班
											<a-tooltip>
												<template #title>离职日当天变更为“已离职”，当日自动解除绑定员工端，无法进行打卡、审批等员工服务。</template>
												<a-icon type="question-circle" />
											</a-tooltip>
										</a-radio>
									</p>
									<p class="item_p">
										<a-radio value="unneed_work"> 离职日当天需要上班
											<a-tooltip>
												<template #title>离职日次日变更为“已离职”，当日依旧可以使用员工端进行打卡、审批等员工服务。</template>
												<a-icon type="question-circle" />
											</a-tooltip>
										</a-radio>
									</p>
								</a-radio-group>
							</div> -->
							
							<div v-if="item.type=='leave_rule'">
								<a-radio-group  @change='set_info(item)' v-model:value="item.value.type">
									<p class="item_p">
										<a-radio value="need_work"> 离职日当天无需上班
											<a-tooltip>
												<template #title>离职日当天变更为“已离职”，当日自动解除绑定员工端，无法进行打卡、审批等员工服务。</template>
												<a-icon type="question-circle" />
											</a-tooltip>
										</a-radio>
									</p>
									<p class="item_p">
										<a-radio value="unneed_work"> 离职日当天需要上班
											<a-tooltip>
												<template #title>离职日次日变更为“已离职”，当日依旧可以使用员工端进行打卡、审批等员工服务。</template>
												<a-icon type="question-circle" />
											</a-tooltip>
										</a-radio>
									</p>
								</a-radio-group>
							</div>

						</div>
					</div>
				</div>

			</div>
		</div>
		<a-modal v-model:visible="addVisible" :title="addType=='add'?'新增工号规则':'编辑工号规则'" @ok="addOk">
		    <a-form   :model="formstatus" :rules="rules"  :label-col="labelColadd" :wrapper-col="wrapperColadd" >
				
		        <a-form-item label="规则名称" >
		            <a-input  v-model:value="formstatus.name" placeholder="请输入规则名称" />
		        </a-form-item>
		        <a-form-item label="最新未用工号">
		            <a-input  v-model:value="formstatus.code_begin" placeholder="请输入最新未用工号" />
		        </a-form-item>
				<a-form-item label="工号前缀">
				    <a-input  v-model:value="formstatus.number_prefix"  placeholder="请输入规则工号前缀"/>
				</a-form-item>
				<a-form-item label="数字位数">
				    <a-select class="mar8 w100"  v-model:value="formstatus.figure">
				    	<a-select-option :value="y.value" v-for="(y,index) in choosenum" :key='index'>{{y.label}}位数
				    	</a-select-option>
				    </a-select>
				</a-form-item>
				<a-form-item label="描述">
				    <a-input  v-model:value="formstatus.describe" placeholder="请输入规则描述"/>
				</a-form-item>
		    </a-form>
		</a-modal>
	</div>
</template>

<script>
	import {
		getPersonnelRulesInfo,savePersonnelRules,addCodeRule,delCodeRule,getCodeRuleDetail,editCodeRule
	} from '@/api/enterprise.js'
	import {
		getSelfData
	} from '@/api/public'
	import {
		message,
		TreeDataItem,
		TreeDragEvent,
		DropEvent,
		Modal
	} from 'ant-design-vue';

	export default {
		components: {

		},
		data() {
			return {
				rules: {
				      name: [
				        { required: true, message: '请填写规则名称', trigger: 'blur' },
				      ],
					  code_begin: [
					    { required: true, message: '请填写工号规则', trigger: 'blur' },
					  ]
					  },
				labelColadd:{span: 6},
				wrapperColadd:{span: 16},
				addType:'add',
				addVisible:false,
				choosenum:[{label:"一",value:1},{label:"二",value:2},{label:"三",value:3},{label:"四",value:4},{label:"五",value:5},{label:"六",value:6},{label:"七",value:7},{label:"八",value:8},{label:"九",value:9},{label:"十",value:10}],
				formstatus:{ name: '',},
				getCurrentAnchor:'#entry_rule',
				txDay:[],
				years: [],
				retire: [],
				againMonth:[],
				select_list2: [],
				ViewRangeType: '1',
				ViewBookEmployeestype: '1',
				ViewRangeTypeVisit: false,
				ViewBookEmployeesVist: false,
				departmentDataFilter: [],
				labelColEdit: {
					span: 2
				},

				wrapperCol: {
					span: 12
				},

				scaleType: [],
				select_list: [],
				write_off_reason: [],
				chooseList: [],
				mailInfo_list: [
					[],
					[],
					[]
				],
			}

		},
		created() {
			this.get_info();
			for (let i =16;i<=100;i++) {
				this.txDay.push( i )
			}
			for (let i =40;i<=80;i++) {
				this.retire.push( i )
			}
			for (let i =1;i<=60;i++) {
				this.againMonth.push( i )
			}
			
		},
		 mounted() {
		  this.targetOffset = window.innerHeight / 2;
		  },
		methods: {
			cancel(){},
			del_info(item){
				delCodeRule({data:{id:item.id},toast:true}).then(res=>{
					this.get_info();
				})
			},
			go_info(item){
				this.addVisible = true
				this.formstatus = item 
				this.addType = 'edit'
			},
			addOk(){
				if(!this.formstatus.name ){
					message.error('请填写规则名称')
					return false;
				}
				if(!this.formstatus.code_begin ){
					message.error('请填写规则规则')
					return false;
				}
				if(this.addType == 'edit'){
					editCodeRule({data:this.formstatus,toast:true}).then(res=>{
						this.get_info();
						this.formstatus = {}
						this.addVisible = false
						this.addType = 'add'
					})
				}else {
					addCodeRule({data:this.formstatus,toast:true}).then(res=>{
						this.get_info();
						this.formstatus = {}
						this.addVisible = false
						this.addType = 'add'
					})
				}
				
			},
			go_none_list() {
				this.$router.push({
					path: '/PersonnelRules/NoneCodeUserList'
				})
			},
			go_list(type){
				this.$router.push({path:'/PersonnelRules/addUserRule',query:{type:type}})
			},
			set_info(item){
					savePersonnelRules({data:item}).then(res=>{
						message.success('操作成功');
						this.get_info();
					})
				
			},
			get_info() {
				getPersonnelRulesInfo({
					data: {}
				}).then(res => {
					this.mailInfo_list = res.data.list
					this.getCurrentAnchor = '#'+res.data.list[0].type
				})
			},

			handle_submit() {

			}
		},

	}
</script>

<style lang="less" scoped>
	@import url("../../assets/less/app.less");
	@import 'info.less';

	.img_code {
		height: 30px;
		width: 70px;
		margin-left: 12px;
		margin-right: 12px;
	}

	.text_code {
		height: 30px;
		width: 98px;
		margin-left: 12px;
		// margin-right: 12px;
	}

	.text_code2 {
		width: 130px;
	}

	.code_p {
		display: flex;
		margin-bottom: 12px;
	}

	.info {
		line-height: 44px;

	}

	.mailInfoEdit {
		padding: 32px;
		margin-top: 12px;
		background-color: #f8f8f9;
		border: 1px dashed #e1e6eb;
		border-radius: 4px;
	}

	.chooseItem {
		border: 1px solid @cq_text_c6;
		color: @cq_text_c6;
		margin-right: 12px;
		margin-bottom: 12px;
		text-align: center;
		border-radius: 6px;
		position: relative;
		line-height: 22px;
		padding: 5px 15px 5px 10px;

	}

	.chooseItemC {
		border-color: @cq_color;
		color: @cq_color;

	}

	.chooseItemactieve {
		position: absolute;
		right: -1px;
		height: 24px;
		width: 24px;
		bottom: -1px;

	}

	.infoBox {
		margin-top: 20px;

		.item_p {
			line-height: 32px;
			margin-bottom: 10px;
		}

		.mar8 {
			margin-right: 8px;
		}

		.mar_30 {
			margin-top: 30px;
		}
	}

	.FieldSettingsBoxTitle {
		display: flex;
		width: 100%;
		padding: 8px 12px;
		border-bottom: 1px solid @cq_line_e;
	
		span:nth-child(1) {
			flex: 1;
			margin-left: 8px;
		}
	
		span:nth-child(2) {
			flex: 3;
		}
	
		span:nth-child(3) {
			flex: 2;
			text-align:center;
		}
		
	}
	.w100{
		width: 100px;
	}

.ltr {
  width: 20px;
  transition: width 1s;
 box-shadow: -6px 0 16px rgba(73,80,96,.1);
  position: absolute;right: 30px;z-index: 12;top: 200px;
}

.ltr:hover {
  width: 140px;
  box-shadow: none;
}
/deep/.ant-anchor-ink::before{
	width: 5px;
}
/deep/.ant-anchor-ink-ball{
	width: 10px;
	height: 10px;
}
/deep/.ant-anchor-link{
	line-height: 24px;
	padding-left: 16px;
}
</style>
